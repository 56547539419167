import { Box, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  UIButton,
  UISelect,
  UISelectCheckmarks,
  UITextField,
} from "components";
import { AddCircleOutline as AddCircleOutlineIcon } from "@mui/icons-material";
import { ModalVacancyReason } from "./ModalVacancyReason";
import { useState } from "react";
import {
  recruitmentSelect,
  setVacancyReasons,
} from "@redux/slices/recruitment";
import { useSelector } from "react-redux";
import { Description } from "./Description";

function replaceValueWithId(array) {
  return array.map((obj) => {
    const { value, ...rest } = obj;
    return { _id: value, ...rest };
  });
}

export const FormStaffRequest = ({
  formik,
  canAdd,
  canChange,
  canAddVacancyReasons,
  jobsPosition,
}) => {
  const { t } = useTranslation(["recruitment"]["general"]);

  const [open, setOpen] = useState(false);
  const { vacancyReasons } = useSelector(recruitmentSelect);
  const { status, _id } = formik.values;
  const bool_id = Boolean(_id);

  const disabled = status === "PENDING" ||
    (!canAdd && status === "REVIEW_REQUIRED") ||
    (!canChange && bool_id) ||
    formik.isSubmitting;

  const vacancies = replaceValueWithId(vacancyReasons);

  const job = jobsPosition.find(
    (obj) => obj._id === formik.values?.jobPosition,
  );

  const disponibles = job?.quantity - job?.occupiedPositions;

  return (
    <>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} sm={12} md={6} lg={4}>
          <UISelect
            label={t("recruitment:JobPosition")}
            name="jobPosition"
            formik={formik}
            options={jobsPosition}
            disabled={disabled}
            isrequired={true}
          />
        </Grid>
        <Grid item={true} xs={12} sm={12} md={6} lg={3}>
          <UITextField
            label={t("recruitment:VacanciesPlus")}
            name="quantity"
            formik={formik}
            disabled={disabled || !job}
            isrequired={true}
            type="number"
            InputProps={{
              inputProps: {
                min: 1,
                max: 99,
              },
            }}
          />
        </Grid>
        <Grid item={true} xs={12} sm={12} md={12} lg={5}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                sx={{ width: "100%" }}
                spacing={2}
              >
                <Box sx={{ flexGrow: "1" }}>
                  <UISelectCheckmarks
                    label={t("recruitment:VacancyReasonPlus")}
                    name={"vacancyReason"}
                    formik={formik}
                    options={vacancies}
                    disabled={disabled}
                    fieldRender={"label"}
                    isrequired="true"
                  />
                </Box>

                {canAddVacancyReasons && status === "REVIEW_REQUIRED" && (
                  <Box sx={{ flexGrow: "auto" }}>
                    <UIButton
                      sx={{
                        height: "100%",
                      }}
                      onClick={() =>
                        setOpen(true)}
                    >
                      <AddCircleOutlineIcon />
                    </UIButton>
                    <ModalVacancyReason
                      open={open}
                      setOpen={setOpen}
                      vancancyReasons={vacancyReasons}
                      setVancancyReasons={setVacancyReasons}
                    />
                  </Box>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12}>
          <Description
            job={{
              quantity: job?.quantity,
              occupiedPositions: job?.occupiedPositions,
              disponibles: disponibles ? disponibles : 0,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
