import {
  Grid,
  Stack,
  Typography,
  Avatar,
  List,
  ListItem,
  Divider,
  ListItemText,
  Chip,
} from "@mui/material";
import { useSelector } from "react-redux";
import { profileSelect } from "@redux/slices/profile";
import { useTranslation } from "react-i18next";
import { generalSelect } from "@redux/slices/general";
import { getLabel } from "utils";
import { UIButton } from "components";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";

import avatar from "assets/illustrations/avatar.jpeg";

export const PresentationLetter = () => {
  const { t } = useTranslation(["employee"]["options"]);

  const { countries } = useSelector(generalSelect);
  const { employeeSelected } = useSelector(profileSelect);

  return (
    <>
      <Grid container spacing={4} justifyContent="center" alignItems="flex-end">
        <Grid item={true} xs={12} md={6}>
          <Stack justifyContent="center" alignItems="center">
            <Avatar
              alt="avatar"
              src={employeeSelected?.thumbnail ?? avatar}
              sx={{ width: 124, height: 124 }}
            />
            <br />
            <Typography variant="subtitle2" gutterBottom>
              {`${employeeSelected?.fullName}`}
            </Typography>
            <Typography variant="overline" display="block" gutterBottom>
              {employeeSelected?.email}
            </Typography>
          </Stack>

          <Divider />

          <ListItem alignItems="flex-start">
            <Grid container spacing={2}>
              <Grid item={true} xs={12} md={4}>
                <ListItemText
                  primary={t("employee:CurrentPosition")}
                  secondary={<>{employeeSelected?.position}</>}
                />
              </Grid>
              <Grid item={true} xs={12} md={4}>
                <ListItemText
                  primary={t("employee:department")}
                  secondary={<>{employeeSelected?.department}</>}
                />
              </Grid>
              <Grid item={true} xs={12} md={4}>
                <ListItemText
                  primary={t("employee:Lugardetrabajo")}
                  secondary={<>{employeeSelected?.workLocation}</>}
                />
              </Grid>
            </Grid>
          </ListItem>

          <Divider />

          <ListItem alignItems="flex-start">
            <Grid container spacing={2}>
              <Grid item={true} xs={12} md={4}>
                <ListItemText
                  primary={t("employee:cellphoneNumber")}
                  secondary={<>{employeeSelected?.cellphoneNumber}</>}
                />
              </Grid>
              <Grid item={true} xs={12} md={4}>
                <ListItemText
                  primary={t("employee:extensionNumber")}
                  secondary={<>{employeeSelected?.extensionNumber}</>}
                />
              </Grid>
              <Grid item={true} xs={12} md={4}>
                <ListItemText
                  primary={t("employee:telephoneNumber")}
                  secondary={<>{employeeSelected?.telephoneNumber}</>}
                />
              </Grid>
            </Grid>
          </ListItem>

          <Divider />

          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <ListItem alignItems="flex-start">
              <Grid container spacing={2}>
                <Grid item={true} xs={12} md={4}>
                  <ListItemText
                    primary={t("employee:InicioRelaciónLaboral")}
                    secondary={<>{employeeSelected?.dateJoiningCompany}</>}
                  />
                </Grid>
                <Grid item={true} xs={12} md={4}>
                  <ListItemText
                    primary={t("employee:FechaDeNacimiento")}
                    secondary={
                      <>{employeeSelected?.dateOfBirth.split("T")[0]}</>
                    }
                  />
                </Grid>
                <Grid item={true} xs={12} md={4}>
                  <ListItemText
                    primary={t("employee:PaísDeOrigen")}
                    secondary={
                      <>{getLabel(countries, employeeSelected?.nationality)}</>
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>

            <Divider />

            <ListItem alignItems="flex-start">
              <Grid container spacing={2}>
                <Grid item={true} xs={12}>
                  <ListItemText
                    primary={t("employee:Pasatiempos")}
                    secondary={
                      <>
                        {employeeSelected?.hobbies?.map((option, index) => (
                          <Chip
                            sx={{ margin: "5px" }}
                            key={index}
                            label={option}
                          />
                        ))}
                      </>
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>

            <Divider />

            <ListItem alignItems="flex-start">
              <Grid container spacing={2}>
                <Grid item={true} xs={12}>
                  <ListItemText
                    primary={t("employee:Language")}
                    secondary={
                      <>
                        {employeeSelected?.languages?.map((option, index) => (
                          <Chip
                            sx={{ margin: "5px" }}
                            key={index}
                            label={option}
                          />
                        ))}
                      </>
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
            <Divider />

            <ListItem alignItems="flex-start">
              <Grid container spacing={2}>
                <Grid item={true} xs={12}>
                  <ListItemText
                    primary={t("employee:Skill")}
                    secondary={
                      <>
                        {employeeSelected?.skills?.map((option, index) => (
                          <Chip
                            sx={{ margin: "5px" }}
                            key={index}
                            label={option}
                          />
                        ))}
                      </>
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
              <UIButton
                variant="contained"
                startIcon={<ChevronLeftIcon />}
                onClick={() => window.history.back()}
                sx={{}}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </>
  );
};
