import { useEffect, useState } from "react";
import { Avatar, Box, Divider, Paper, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";
import { parameterizationSelect } from "@redux/slices/parameterization";
import { setEmployeeSelected } from "@redux/slices/profile";
import { Carousel } from "rsuite";
import { getCoworker } from "api";

import avatar from "assets/illustrations/avatar.jpeg";
import { useNavigate } from "react-router-dom";

function obtenerElementosAleatorios(array) {
  array.sort(() => Math.random() - 0.5);
  return array.slice(0, 10);
}

export const Coworkers = ({ app = "/cereza-organization/" }) => {
  const { t } = useTranslation(["home"]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector(authUserSelect);
  const { mode } = useSelector(parameterizationSelect);

  const [coworkers, setCoworkers] = useState([]);

  useEffect(() => {
    const lista = async () => {
      try {
        let { data } = await getCoworker();
        data = Object.keys(data).map((key) => data[key]);

        let colegas = data.filter((obj) => obj.email !== user.email);

        colegas = obtenerElementosAleatorios(colegas);
        setCoworkers(colegas);
      } catch (error) {
        console.error(error);
      }
    };

    lista();
  }, []);

  const LetMeSee = async (worker) => {
    dispatch(setEmployeeSelected(worker));
    navigate(`${app}presentation-letter`);
  };

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          width: "350px",
          height: "100% !important",
          "@media (max-width: 600px)": {
            width: "100%",
          },
        }}
      >
        <Box
          p={2}
          sx={{
            textAlign: "center",
          }}
        >
          <Typography variant="h4" gutterBottom>
            {user?.names}!
          </Typography>
          <Divider>{t("home:ConoceColega")}</Divider>
          <br />
          <Carousel
            autoplay
            className="custom-slider"
            shape={"bar"}
            style={{
              height: "281px",
              padding: "2.2rem 0rem",
              backgroundColor: mode === "light" && "#e5e5ea",
            }}
          >
            {coworkers.map((co, index) => (
              <Stack
                key={index}
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: mode === "light" && "#e5e5ea",
                  cursor: "pointer",
                }}
                onClick={() => LetMeSee(co)}
              >
                <Avatar
                  alt="avatar"
                  src={co?.thumbnail ?? avatar}
                  sx={{ width: 124, height: 124 }}
                />
                <br />
                <Typography variant="subtitle2" gutterBottom>
                  {co?.fullName}
                </Typography>
                <Typography variant="overline" display="block" gutterBottom>
                  {co?.email}
                </Typography>
              </Stack>
            ))}
          </Carousel>
        </Box>
      </Paper>
    </>
  );
};
