import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Toast, UIButton } from "components";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Swal from "sweetalert2";
import { TypesOfPermits } from "../CreateRequests/TypesOfPermits";
import { VacationRequest } from "../CreateRequests/VacationRequest";
import { JobDescription } from "modules/Organization/JobDescription/JobDescription";
import { useDispatch } from "react-redux";
import { setJobDescription } from "@redux/slices/jobDescription";
import { managerErrors } from "utils/functions";
import { singDocument } from "api";
import { useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";
import { JustifyForm } from "modules/ShiftAttendance/AttendanceReports/JustifyForm";
import { StaffRequest } from "modules/Recruitment/StaffRequests/StaffRequest";
import { setStaffRequest } from "@redux/slices/recruitment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const SignModal = ({
  open,
  setOpen,
  typeDocument,
  request,
  signAuth,
  confirmSign,
}) => {
  const { t } = useTranslation(["request"]);
  const { user } = useSelector(authUserSelect);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  if (typeDocument === "staff") {
    dispatch(setStaffRequest(request));
  }

  if (typeDocument === "jobPosition") {
    dispatch(setJobDescription(request));
  }

  const onSubmit = async (action, reason = null) => {
    try {
      setIsSubmitting(true);
      let data = {};
      let signAction = "sign";

      if (action !== "APPROVE") {
        data["reason"] = reason;
        data["type"] = action;
        signAction = "reject";
      }

      await singDocument(signAuth._id, user._id, data, signAction);
      Toast.fire({
        icon: "success",
        title: t("general:AcciónExitosa"),
      });
      setIsSubmitting(false);
      setOpen(false);
      confirmSign();
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      managerErrors(error?.response?.data?.message);
    }
  };

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {t("request:ActionsForRequest")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {typeDocument === "permissions" && (
          <TypesOfPermits request={request} readOnly={true} setOpen={setOpen} />
        )}
        {typeDocument === "vacation" && (
          <VacationRequest
            request={request}
            readOnly={true}
            setOpen={setOpen}
          />
        )}
        {typeDocument === "jobPosition" && (
          <JobDescription
            readOnly={true}
            application="employee"
            setOpen={setOpen}
          />
        )}
        {typeDocument === "justifications" && (
          <JustifyForm
            justificationRequest={request}
            readOnly={true}
            setOpen={setOpen}
          />
        )}
        {typeDocument === "staff" && (
          <StaffRequest request={request} readOnly={true} setOpen={setOpen} />
        )}
      </DialogContent>
      <DialogActions>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 1, md: 1 }}
        >
          <UIButton
            label={t("request:aprobar")}
            variant="contained"
            onClick={() => onSubmit("APPROVE")}
            loading={isSubmitting}
            sx={{}}
          />
          <UIButton
            label={t("request:solicitarCambio")}
            variant="contained"
            onClick={() => {
              setOpen(false);
              Swal.fire({
                input: "textarea",
                inputLabel: t("request:Message"),
                inputPlaceholder: t("request:Typeyourmessagehere"),
                confirmButtonText: t("request:enviarMessage"),
                cancelButtonText: t("request:cancelar"),
                inputAttributes: {
                  "aria-label": t("request:Typeyourmessagehere"),
                },
                showCancelButton: true,
                preConfirm: () => {
                  let value = document.getElementById("swal2-textarea").value;

                  if (value) {
                    return value;
                  } else {
                    Swal.showValidationMessage(
                      t("request:MessageReviewRequired"),
                    );
                  }
                },
              }).then((result) => {
                if (result?.isConfirmed) {
                  setOpen(true);
                  onSubmit("TEMPORAL", result?.value);
                } else {
                  setOpen(true);
                }
              });
            }}
            loading={isSubmitting}
            sx={{}}
          />
          <UIButton
            label={t("request:rechazar")}
            variant="contained"
            onClick={() => {
              setOpen(false);
              Swal.fire({
                input: "textarea",
                inputLabel: t("request:Message"),
                inputPlaceholder: t("request:Typeyourmessagehere"),
                confirmButtonText: t("request:enviarMessage"),
                cancelButtonText: t("request:cancelar"),
                inputAttributes: {
                  "aria-label": t("request:Typeyourmessagehere"),
                },
                showCancelButton: true,
                preConfirm: () => {
                  let value = document.getElementById("swal2-textarea").value;

                  if (value) {
                    return value;
                  } else {
                    Swal.showValidationMessage(
                      t("request:MessageRejectRequired"),
                    );
                  }
                },
              }).then((result) => {
                const message = result?.value;
                if (result?.isConfirmed) {
                  Swal.fire({
                    title: t("general:Are"),
                    text: t("general:Youwon"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: t("request:siRechazalo"),
                    cancelButtonText: t("request:cancelar"),
                  }).then((result) => {
                    if (result?.isConfirmed) {
                      setOpen(true);
                      onSubmit("FINAL", message);
                    } else {
                      setOpen(true);
                    }
                  });
                } else {
                  setOpen(true);
                }
              });
            }}
            loading={isSubmitting}
            sx={{}}
          />
        </Stack>
      </DialogActions>
    </BootstrapDialog>
  );
};
