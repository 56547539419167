import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { forwardRef } from "react";

export const UIButton = forwardRef(
  ({ width = "100%", type = "button", label, children, sx, ...props }, ref) => {
    let addSX = sx ? sx : { width: width };

    return (
      <LoadingButton
        variant="contained"
        sx={addSX}
        type={type}
        {...props}
        ref={ref}
      >
        {label}
        {children}
      </LoadingButton>
    );
  }
);

UIButton.propTypes = {
  width: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
};
