import { useTranslation } from "react-i18next";
import { Toast, UIButton, UIDatePicker, UITextField } from "components";
import { Alert, AlertTitle, Box, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import {
  deleteScheduledPermissions,
  postScheduledPermissions,
  putScheduledPermissions,
} from "api";
import { useDispatch } from "react-redux";
import { setUpdateStaffs } from "@redux/slices/recruitment";
import { setRequestUpdated } from "@redux/slices/requests";

import * as Yup from "yup";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const VacationRequest = ({
  setOpen = null,
  request = null,
  readOnly = false,
}) => {
  const { t } = useTranslation(["request"]["general"]);

  const dispatch = useDispatch();
  readOnly = readOnly ? readOnly : request?.status === "PENDING";

  const validationSchema = Yup.object().shape({
    startDate: Yup.date().test(
      "is-before-endDate",
      t("request:startDateTest"),
      function (value) {
        const { endDate } = this.parent;
        return (
          !endDate ||
          dayjs(value).isBefore(dayjs(endDate)) ||
          dayjs(value).isSame(dayjs(endDate))
        );
      },
    ),
  });

  const onSubmit = async (values) => {
    values.startDate = dayjs.utc(values.startDate).format("YYYY-MM-DDTHH:mm");
    values.endDate = dayjs.utc(values.endDate).format("YYYY-MM-DDTHH:mm");

    let message = "";
    try {
      await validationSchema.validate(values, {
        abortEarly: false,
        context: values,
      });

      try {
        if (values._id) {
          const { data } = await putScheduledPermissions(values._id, values);
          message = t("request:ScheduledPermissionsSuccessfullyUpdate");
          dispatch(
            setRequestUpdated({
              type: "vacation",
              action: "update",
              data: data,
            }),
          );
        } else {
          const { data } = await postScheduledPermissions(values);
          message = t("request:ScheduledPermissionsSuccessfullyCreated");
          dispatch(
            setRequestUpdated({
              type: "vacation",
              action: "add",
              data: data,
            }),
          );
        }
        dispatch(setUpdateStaffs(true));
        setOpen(false);
        Toast.fire({ icon: "success", title: message });
      } catch (error) {
        console.log(error.response);
        if (error?.response?.data?.message) {
          Toast.fire({
            icon: "error",
            title: error.response.data.message,
          });
        }
        console.log("Error to submit form: ", error);
      }
    } catch (e) {
      Toast.fire({
        icon: "error",
        title: e.errors.join("; "),
      });
    }
  };

  const deleteItem = async (item) => {
    try {
      await deleteScheduledPermissions(item._id);
      dispatch(setUpdateStaffs(true));
      dispatch(
        setRequestUpdated({
          type: "vacation",
          action: "delete",
          data: item,
        }),
      );
      Toast.fire({
        icon: "success",
        title: t("request:ScheduledPermissionsSuccessfullyDelete"),
      });
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: t("request:ScheduledPermissionsErrorDelete"),
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={
          request
            ? request
            : {
                type: "VACATION",
                comment: "",
                startDate: "",
                endDate: "",
              }
        }
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} md={4} lg={3}>
                <UIDatePicker
                  formik={formik}
                  label={t("request:startDate")}
                  name="startDate"
                  customDisabled={"Before"}
                  disabled={formik.isSubmitting || readOnly}
                  isrequired={true}
                />
              </Grid>
              <Grid item={true} xs={12} md={4} lg={3}>
                <UIDatePicker
                  formik={formik}
                  label={t("request:endDate")}
                  name="endDate"
                  disabled={formik.isSubmitting || readOnly}
                  isrequired={true}
                  props={{ minDate: dayjs.utc(formik.values.startDate) }}
                />
              </Grid>
              <Grid item={true} xs={12} md={8} lg={6}>
                <UITextField
                  label={t("request:Comentario")}
                  isrequired={false}
                  name={"comment"}
                  formik={formik}
                  disabled={formik.isSubmitting || readOnly}
                />
              </Grid>
            </Grid>
            {!readOnly && (
              <Box
                mt={2}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" gap="1em">
                  <UIButton
                    type="submit"
                    label={
                      formik?.values?._id
                        ? t("general:Actualizar")
                        : t("general:Guardar")
                    }
                    loading={formik.isSubmitting}
                  />
                  <UIButton
                    label={t("general:Eliminar")}
                    onClick={() => {
                      setOpen(false);
                      Swal.fire({
                        title: t("general:Are"),
                        text: t("general:Youwon"),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: t("general:deleteit"),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deleteItem(formik.values);
                        }
                      });
                    }}
                    disabled={formik.isSubmitting}
                  />
                </Box>
              </Box>
            )}
          </Form>
        )}
      </Formik>
      {request?.signStatus &&
        Object.keys(request?.signStatus).map(
          (key) =>
            request?.signStatus[key]?.msg !== null && (
              <>
                <hr />
                <Alert severity="info">
                  <AlertTitle>{t("general:Mensaje")}</AlertTitle>
                  {request?.signStatus[key]?.msg}
                </Alert>
              </>
            ),
        )}
    </>
  );
};
