import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProfileTabs, Toast, UIButton } from "components";
import { FormPersonalInformation } from "./FormPersonalInformation";
import { Box, Stack, Switch, Typography, Paper } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { profileSelect, setEmployeeSelected } from "@redux/slices/profile";
import { enableOrDisableUser, updateEmployee } from "api";
import { managerErrors } from "utils";
import { useAccess } from "hooks";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { pathEmployee } from "helpers";
import { ChangeEmployeeNumberModal } from "./ChangeEmployeeNumberModal";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const createInitialValues = (values) => {
  if (values || values !== undefined) {
    const iValues = { ...values };
    delete iValues.isActive;
    delete iValues.createdAt;
    delete iValues.updatedAt;
    return iValues;
  } else {
    return {
      names: "",
      surnames: "",
      email: "",
      gender: "",
      dateOfBirth: dayjs(),
      bloodType: "",
      nationality: "",
      maritalStatus: "",
      hobbies: [],
      socialSecurityNumber: "",
      taxIdentificationNumber: "",
      photo: "",
      number: "",
    };
  }
};

export const PersonalInformation = ({
  application = "organization",
  user = null,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["employee"]["general"]);

  const dispatch = useDispatch();
  const { employeeSelected } = user ? user : useSelector(profileSelect);
  const [initialValues, setInitialValues] = useState(
    createInitialValues(employeeSelected)
  );

  const { HasPermissions, accessCodeName } = useAccess();
  const { canAdd, canChange } = HasPermissions(
    pathEmployee(application, "/profile/collaborator/personal-information"),
    application
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setInitialValues(createInitialValues(employeeSelected));
  }, [employeeSelected]);

  const handleSubmit = async (values) => {
    try {
      const updatedValues = { ...values };
      const { data } = await updateEmployee(
        updatedValues,
        employeeSelected._id
      );
      dispatch(setEmployeeSelected(data));
      Toast.fire({
        icon: "success",
        title: t("employee:SuccessfullyUpdatedEmployee"),
      });
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error submitting form:", err);
    }
  };

  const handleChange = async () => {
    try {
      const { data } = await enableOrDisableUser(
        employeeSelected._id,
        !employeeSelected.isActive
      );
      dispatch(setEmployeeSelected(data));
      Toast.fire({
        icon: "success",
        title:
          !employeeSelected.isActive === true
            ? t("employee:enableEmployee")
            : t("employee:disableEmployee"),
      });
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error submitting form:", err);
    }
  };

  const enableOrDisableDirectory = async (values) => {
    try {
      const updatedValues = {
        ...values,
        showInDirectory: !values.showInDirectory,
      };
      const { data } = await updateEmployee(
        updatedValues,
        employeeSelected._id
      );
      dispatch(setEmployeeSelected(data));
      Toast.fire({
        icon: "success",
        title: t("employee:SuccessfullyUpdatedEmployee"),
      });
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error submitting form:", err);
    }
  };

  return (
    <>
      <ProfileTabs tab_initial="collaborator" application={application} />
      <br />
      <Paper>
        <Box p={4}>
          <Typography variant="h5">
            {t("employee:InformaciónPersonal")}
          </Typography>
          <br />
          <Stack
            mb={2}
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 1, md: 1 }}
          >
            {application === "organization" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "auto",
                }}
              >
                <Switch
                  color="secondary"
                  checked={employeeSelected.isActive}
                  onChange={handleChange}
                />
                <Typography variant="subtitle2" sx={{ margin: "auto 0" }}>
                  {t("employee:isActive")}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "auto",
              }}
            >
              <Switch
                color="secondary"
                checked={employeeSelected?.showInDirectory}
                onChange={() => enableOrDisableDirectory(employeeSelected)}
              />
              <Typography variant="subtitle2" sx={{ margin: "auto 0" }}>
                {t("employee:MostrarEnElDirectorio")}
              </Typography>
            </Box>
          </Stack>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form id="d" autoComplete="off">
                <FormPersonalInformation
                  formik={formik}
                  canAdd={canAdd}
                  canChange={canChange}
                />
                <Stack
                  mt={2}
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  {application === "organization" && (
                    <UIButton
                      variant="contained"
                      startIcon={<ChevronLeftIcon />}
                      onClick={() =>
                        navigate("/cereza-organization/employees/profiles")
                      }
                      disabled={formik.isSubmitting}
                      sx={{}}
                    />
                  )}
                  {((canAdd && !formik?.values?._id) ||
                    (canChange && formik?.values?._id)) && (
                    <UIButton
                      type="submit"
                      label={
                        formik?.values?._id
                          ? t("general:Actualizar")
                          : t("general:Guardar")
                      }
                      loading={formik.isSubmitting}
                      sx={{}}
                    />
                  )}
                  {formik?.values?._id &&
                    accessCodeName("change_employee_number") &&
                    application === "organization" && (
                      <UIButton
                        variant="contained"
                        label={t("employee:CambiarNumeroEmpleado")}
                        onClick={() => setOpen(true)}
                        disabled={formik.isSubmitting}
                        sx={{}}
                      />
                    )}
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Paper>
      {initialValues?._id &&
        accessCodeName("change_employee_number") &&
        application === "organization" && (
          <ChangeEmployeeNumberModal
            open={open}
            setOpen={setOpen}
            initialValues={initialValues}
          />
        )}
    </>
  );
};
