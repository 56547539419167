import { useState } from "react";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { ProfileTabs, TitleBox, Toast, UIButton } from "components";
import { FormSkillAndCompetencies } from "./FormSkillAndCompetencies";
import { FormLanguage } from "./FormLanguage";
import { deleteReferences, saveReferences, updateReferences } from "api";
import { profileSelect } from "@redux/slices/profile";
import isEqual from "lodash/isEqual";
import Swal from "sweetalert2";
import { useAccess } from "hooks";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { pathEmployee } from "helpers";
import { useNavigate } from "react-router-dom";

export const SkillsSummary = ({
  application = "organization",
  user = null,
}) => {
  const { t } = useTranslation(["employee"]["general"]);
  const { access } = useAccess();
  const navigate = useNavigate();

  const { employeeSelected } = user ? user : useSelector(profileSelect);
  const [newLanguage, setNewLanguage] = useState(false);
  const [newData, setNewData] = useState(false);

  const { HasPermissions } = useAccess();
  const {
    canAdd: canAddSkill,
    canChange: canChangeSkill,
    canDelete: canDeleteSkill,
  } = HasPermissions(
    pathEmployee(application, "/profile/competencies/skills-summary"),
    application,
  );
  const {
    canAdd: canAddLanguage,
    canChange: canChangeLanguage,
    canDelete: canDeleteLanguage,
  } = HasPermissions(
    pathEmployee(application, "/profile/competencies/skills-summary-languages"),
    application,
  );

  const handleSubmitSkills = async (values, { resetForm }) => {
    try {
      if (values?._id) {
        const { data } = await updateReferences(
          values,
          employeeSelected._id,
          "skills",
          values._id,
        );
        setNewData(!newData);
        resetForm();
        Toast.fire({
          icon: "success",
          title: t("employee:SuccessfullyUpdatedEmployee"),
        });
      } else {
        const { data } = await saveReferences(
          values,
          employeeSelected._id,
          "skills",
        );
        setNewData(!newData);
        resetForm();
        Toast.fire({
          icon: "success",
          title: t("employee:SuccessfullyUpdatedEmployee"),
        });
      }
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: `${err.response.data.message}`,
      });
    }
  };

  const handleSubmitLanguage = async (values, { resetForm }) => {
    try {
      if (values?._id) {
        const { data } = await updateReferences(
          values,
          employeeSelected._id,
          "languages",
          values._id,
        );
        setNewLanguage(!newLanguage);
        resetForm();
        Toast.fire({
          icon: "success",
          title: t("employee:SuccessfullyUpdatedEmployee"),
        });
      } else {
        const { data } = await saveReferences(
          values,
          employeeSelected._id,
          "languages",
        );
        setNewLanguage(!newLanguage);
        resetForm();
        Toast.fire({
          icon: "success",
          title: t("employee:SuccessfullyUpdatedEmployee"),
        });
      }
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: `${err.response.data.message}`,
      });
    }
  };

  const deleteItem = async (formik, ref) => {
    try {
      const { data } = await deleteReferences(
        employeeSelected._id,
        ref,
        formik.values._id,
      );
      setNewLanguage(!newLanguage);
      setNewData(!newData);
      formik.setValues(formik.initialValues);
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: `${err.response.data.message}`,
      });
    }
  };

  return (
    <>
      <ProfileTabs tab_initial="competencies" application={application} />
      {access(
        pathEmployee(application, "/profile/competencies/skills-summary"),
      ) && (
        <Paper>
          <Box p={4} mt={4}>
            <TitleBox
              text={`${employeeSelected?.names} ${employeeSelected?.surnames}`}
            />
            <Typography variant="h5" mb={2}>
              {t("employee:Skillsandcompetencies")}
            </Typography>
            <Formik
              initialValues={{
                skill: "",
                level: "",
                file: "",
              }}
              onSubmit={handleSubmitSkills}
            >
              {(formik) => (
                <Form id="skills" autoComplete="off">
                  <FormSkillAndCompetencies
                    formik={formik}
                    newData={newData}
                    canAdd={canAddSkill}
                    canChange={canChangeSkill}
                    user={user}
                  />
                  <Stack
                    mt={2}
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                  >
                    {application === "organization" && (
                      <UIButton
                        variant="contained"
                        startIcon={<ChevronLeftIcon />}
                        onClick={() =>
                          navigate("/cereza-organization/employees/profiles")
                        }
                        disabled={formik.isSubmitting}
                        sx={{}}
                      />
                    )}
                    {((canAddSkill && !formik?.values?._id) ||
                      (canChangeSkill && formik?.values?._id)) && (
                      <UIButton
                        type="submit"
                        label={
                          formik?.values?._id
                            ? t("general:Actualizar")
                            : t("general:Guardar")
                        }
                        loading={formik.isSubmitting}
                        sx={{}}
                      />
                    )}
                    {!isEqual(formik?.values, formik?.initialValues) && (
                      <>
                        {canDeleteSkill && formik?.values?._id && (
                          <UIButton
                            label={t("general:Eliminar")}
                            onClick={() => {
                              Swal.fire({
                                title: t("general:Are"),
                                text: t("general:Youwon"),
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: t("general:deleteit"),
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  deleteItem(formik, "skills");
                                  Swal.fire({
                                    title: t("general:Deleted"),
                                    text: t("general:YourFileHasBeenDeleted"),
                                    icon: "success",
                                  });
                                }
                              });
                            }}
                            disabled={formik.isSubmitting}
                            sx={{}}
                          />
                        )}
                        {canAddSkill && (
                          <UIButton
                            label={t("general:Nuevo")}
                            onClick={() =>
                              formik.setValues(formik.initialValues)
                            }
                            disabled={formik.isSubmitting}
                            sx={{}}
                          />
                        )}
                      </>
                    )}
                  </Stack>
                </Form>
              )}
            </Formik>
          </Box>
        </Paper>
      )}
      {access(
        pathEmployee(
          application,
          "/profile/competencies/skills-summary-languages",
        ),
      ) && (
        <Paper>
          <Box p={4} mt={4}>
            <Typography variant="h5" mb={2}>
              {t("Language")}
            </Typography>
            <Formik
              initialValues={{
                language: "",
                fluencyLevel: "",
                file: "",
              }}
              onSubmit={handleSubmitLanguage}
            >
              {(formik) => (
                <Form id="language" autoComplete="off">
                  <FormLanguage
                    formik={formik}
                    newData={newLanguage}
                    canAdd={canAddLanguage}
                    canChange={canChangeLanguage}
                    user={user}
                  />
                  <Stack
                    mt={2}
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                  >
                    {application === "organization" && (
                      <UIButton
                        variant="contained"
                        startIcon={<ChevronLeftIcon />}
                        onClick={() =>
                          navigate("/cereza-organization/employees/profiles")
                        }
                        disabled={formik.isSubmitting}
                        sx={{}}
                      />
                    )}
                    {((canAddLanguage && !formik?.values?._id) ||
                      (canChangeLanguage && formik?.values?._id)) && (
                      <UIButton
                        type="submit"
                        label={
                          formik?.values?._id
                            ? t("general:Actualizar")
                            : t("general:Guardar")
                        }
                        loading={formik.isSubmitting}
                        sx={{}}
                      />
                    )}
                    {!isEqual(formik?.values, formik?.initialValues) && (
                      <>
                        {canDeleteLanguage && formik?.values?._id && (
                          <UIButton
                            label={t("Eliminar")}
                            onClick={() => {
                              Swal.fire({
                                title: t("general:Are"),
                                text: t("general:Youwon"),
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: t("general:deleteit"),
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  deleteItem(formik, "languages");
                                  Swal.fire({
                                    title: t("general:Deleted"),
                                    text: t("general:YourFileHasBeenDeleted"),
                                    icon: "success",
                                  });
                                }
                              });
                            }}
                            disabled={formik.isSubmitting}
                            sx={{}}
                          />
                        )}
                        {canAddLanguage && (
                          <UIButton
                            label={t("general:Nuevo")}
                            onClick={() =>
                              formik.setValues(formik.initialValues)
                            }
                            disabled={formik.isSubmitting}
                            sx={{}}
                          />
                        )}
                      </>
                    )}
                  </Stack>
                </Form>
              )}
            </Formik>
          </Box>
        </Paper>
      )}
    </>
  );
};
