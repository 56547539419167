import {
  Box,
  Typography,
  Divider,
  Avatar,
  Stack,
  MenuItem,
  Popover,
  MenuList,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import avatar from "assets/illustrations/avatar.jpeg";
import LockResetIcon from "@mui/icons-material/LockReset";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";
import { Language } from "./Language";
import { SignOut } from "@keycloak/logout";
import Swal from "sweetalert2";

export const MenuAvatar = ({
  openAvatar,
  anchorEl,
  handleClose,
  user,
  userThumbnail,
  handleUpdatePassword,
}) => {
  const { t } = useTranslation(["sidenav"]);

  const { keycloakProfile, permissions } = useSelector(authUserSelect);

  return (
    <Popover
      open={openAvatar}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={handleClose}
      sx={{
        mt: 2,
      }}
    >
      <Stack
        spacing={2}
        sx={{
          margin: "15px",
        }}
      >
        <Stack justifyContent="center" alignItems="center">
          <Avatar
            alt="Remy Sharp"
            src={userThumbnail ?? avatar}
            sx={{ width: 124, height: 124 }}
          />
          <Box mt={2} />
          <Typography>
            {user?.names} {user?.surnames}
          </Typography>
          <Typography>{user?.email}</Typography>
        </Stack>
        <Divider />
        <MenuList sx={{ width: 320, maxWidth: "100%" }}>
          <MenuItem
            onClick={() => {
              Swal.fire({
                title: t("sidenav:Are"),
                text: t("sidenav:Youwon"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: t("sidenav:Continuar"),
              }).then((result) => {
                if (result.isConfirmed) {
                  handleUpdatePassword();
                }
              });
            }}
          >
            <ListItemIcon>
              <LockResetIcon />
            </ListItemIcon>
            <ListItemText>{t("CambiarContraseña")}</ListItemText>
          </MenuItem>
          {(keycloakProfile?.is_superuser ||
            permissions?.apps?.organization === true) && (
            <MenuItem
              onClick={() => {
                window.location.href = "/cereza-organization/home/indicators";
              }}
            >
              <ListItemIcon>
                <ManageAccountsIcon />
              </ListItemIcon>
              <ListItemText>{t("CerezaOrganización")}</ListItemText>
            </MenuItem>
          )}
          <Language />
          <Divider />
          <MenuItem
            onClick={() => {
              SignOut();
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>{t("CerrarSesion")}</ListItemText>
          </MenuItem>
        </MenuList>
      </Stack>
    </Popover>
  );
};
