import { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  Toolbar,
  CssBaseline,
  Divider,
  IconButton,
  Stack,
  Badge,
  Avatar,
  Button,
  Alert,
  Typography,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import BrandMarca from "../assets/brand/marca.png";
import avatar from "../assets/illustrations/avatar.jpeg";
import { useSessionStorage } from "@uidotdev/usehooks";
import { calcularDiasPasados } from "helpers";
import { MainBox } from "components";
import { getPlan, postChangeEmail, getFiles } from "api";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SideNav } from "./SideNav";
import { FooterCopyright } from "./Footer";
import { ChangePassword } from "./ChangePassword";
import { MenuAvatar } from "./MenuAvatar";
import { Help } from "./Help";
import { DialogSearch } from "../app/modules/SearchAdvanced/DialogSearch";
import { setOpenDialogSearch } from "../redux/slices/general";
import { authUserSelect } from "@redux/slices/authUser";
import { setCurrentPlan, tenantSettingsSelect } from "@redux/slices/tenant";
import { setEmail, setTimer, publicSelect } from "@redux/slices/public";
import {
  parameterizationSelect,
  setLogo,
} from "@redux/slices/parameterization";
import { Notifications } from "./Notifications";
import { useSocket } from "hooks";
import { getEmployee } from "api/employee";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useNavigate } from "react-router-dom";

const StyleIconButtonAppBar = {
  border: 2,
  borderRadius: "40%",
  display: {
    md: "inline-flex",
    xs: "none",
  },
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const drawerWidth = 400;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    minHeight: "100vh",
    display: "grid",
    gridTemplateRows: `65px auto 50px`,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const DrawerHeaderLeft = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

function isWithinFiveMinutes(dateTime) {
  const inputTime = new Date(dateTime);

  const currentTime = new Date();

  const differenceInMilliseconds = currentTime - inputTime;

  const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

  return differenceInMinutes <= 5;
}

export default function TemplateGridUI({ children }) {
  const [userThumbnail, setUserThumbnail] = useSessionStorage(
    "userThumbnail",
    null,
  );

  const { notifications, startConnection, isConnected, fetchNotifications } =
    useSocket();

  useEffect(() => {
    if (!isConnected) {
      startConnection();
    }
  }, [isConnected, startConnection]);

  const dispatcher = useDispatch();
  const navigate = useNavigate();

  const { user, permissions } = useSelector(authUserSelect);
  const { tenant, currentPlan } = useSelector(tenantSettingsSelect);
  const { timer } = useSelector(publicSelect);
  const { logo, mode, darkBackgroundColor } = useSelector(
    parameterizationSelect,
  );

  const [feedBackUserFetch, setFeedBackUserFetch] = useState({
    spinner: true,
    msg: null,
  });

  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNotification, setAnchorElNotification] = useState(null);

  const { t } = useTranslation(["sidenav"]);
  const theme = useTheme();
  const openAvatar = Boolean(anchorEl);
  const openNotification = Boolean(anchorElNotification);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickNotification = (event) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [stateDrawer, setStateDrawer] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setStateDrawer({ ...stateDrawer, [anchor]: open });
  };

  useEffect(() => {
    const fetchPlan = async () => {
      try {
        const { data } = await getPlan(tenant.currentPlan);
        dispatcher(setCurrentPlan(data));
      } catch (error) {
        console.log(error);
      }
    };

    if (tenant?.currentPlan) {
      fetchPlan();
    }
  }, []);

  useEffect(() => {
    const fetchSource = async () => {
      try {
        const { data } = await getFiles(tenant?._id, tenant?.companyLogo);
        dispatcher(setLogo(data.content));
      } catch (err) {
        console.error("Error getting photo: ", err);
      }
    };
    if (logo === null && tenant?._id && tenant?.companyLogo) {
      fetchSource();
    }

    const fetchThumbnail = async () => {
      try {
        const { data } = await getEmployee("id", user._id);
        setUserThumbnail(data.thumbnail);
      } catch (err) {
        console.error("Error getting photo: ", err);
      }
    };
    if (!userThumbnail && user?.photo && tenant?._id) {
      fetchThumbnail();
    }
  }, []);

  function evaluarNumero(numero) {
    if (numero > 15) {
      return "0";
    } else {
      const faltantes = 15 - numero;
      return faltantes;
    }
  }

  const handleUpdatePassword = async (values) => {
    try {
      setOpenChangePassword(true);

      if (timer === null || !isWithinFiveMinutes(timer)) {
        await postChangeEmail({ email: user?.email });
        await dispatcher(setTimer(new Date()));
      }

      await dispatcher(setEmail(user?.email));
      setFeedBackUserFetch({
        spinner: false,
        msg: null,
      });
    } catch (error) {
      setFeedBackUserFetch({
        spinner: false,
        msg: "Error al intentar esta acción, por favor intenta nuevamente",
      });
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: mode === "dark" ? darkBackgroundColor : "#fff",
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{
          backgroundColor: mode === "dark" ? darkBackgroundColor : "#fff",
        }}
      >
        {currentPlan?.name === "Free" && (
          <Alert severity="warning">
            {`${t("Versióndemoenplanprofesional,quedan")} ${evaluarNumero(
              calcularDiasPasados(currentPlan?.createdAt),
            )} ${t("día(s)deprueba,contrataalgunodenuestrosplanes")}`}
          </Alert>
        )}

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
          }}
        >
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                mr: 2,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <img src={logo !== null ? logo : BrandMarca} height="50px" />
          </Toolbar>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              justifyContent: "end",
              gap: "1rem",
            }}
          >
            <Stack direction="row" spacing={2}>
              {permissions.apps.organization && (
                <Button
                  variant="text"
                  startIcon={<ApartmentIcon />}
                  disableElevation={true}
                  onClick={() => {
                    navigate("/cereza-organization/home/indicators");
                  }}
                >
                  <Typography
                    sx={{
                      display: {
                        md: "inline-flex",
                        xs: "none",
                      },
                    }}
                  >
                    {t("organization:organization")}
                  </Typography>
                </Button>
              )}
              <IconButton
                sx={StyleIconButtonAppBar}
                onClick={() => dispatcher(setOpenDialogSearch(true))}
              >
                <SearchIcon />
              </IconButton>
              <IconButton sx={StyleIconButtonAppBar}>
                <AddIcon />
              </IconButton>

              {/* Notifications Icon */}
              <IconButton
                sx={StyleIconButtonAppBar}
                onClick={handleClickNotification}
                aria-controls={openNotification ? "account-menu" : undefined}
                aria-expanded={openNotification ? "true" : undefined}
                aria-haspopup="true"
              >
                <Badge
                  color="secondary"
                  badgeContent={notifications?.length ?? 0}
                >
                  <NotificationsNoneIcon />
                </Badge>
              </IconButton>

              {/* Help Icon */}
              <IconButton
                sx={StyleIconButtonAppBar}
                onClick={toggleDrawer("right", true)}
              >
                <QuestionMarkIcon />
              </IconButton>

              <StyledBadge
                sx={{ cursor: "pointer" }}
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
                onClick={handleClick}
                aria-controls={openAvatar ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openAvatar ? "true" : undefined}
              >
                <Avatar src={userThumbnail ?? avatar} />
              </StyledBadge>
            </Stack>
            <Notifications
              open={openNotification}
              anchorEl={anchorElNotification}
              handleCloseNotification={handleCloseNotification}
              notifications={notifications}
              fetchNotifications={fetchNotifications}
            />
            <MenuAvatar
              openAvatar={openAvatar}
              anchorEl={anchorEl}
              handleClose={handleClose}
              user={user}
              userThumbnail={userThumbnail}
              handleUpdatePassword={handleUpdatePassword}
            />
          </Box>
        </Box>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <SideNav />
      </Drawer>
      <Help
        stateDrawer={stateDrawer}
        toggleDrawer={toggleDrawer}
        DrawerHeaderLeft={DrawerHeaderLeft}
      />
      <Main open={open}>
        {currentPlan?.name === "Free" && <DrawerHeader />}
        <DrawerHeader />
        <MainBox open={open}>{children}</MainBox>
        <FooterCopyright />
        <DialogSearch />
      </Main>
      <ChangePassword
        open={openChangePassword}
        setOpen={setOpenChangePassword}
        feedBackUserFetch={feedBackUserFetch}
      />
    </Box>
  );
}
