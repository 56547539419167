import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, CircularProgress, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import { Toast, UIButton } from "components";
import Swal from "sweetalert2";
import {
  deleteStaffRequest,
  getJobPositions,
  postStaffRequest,
  putStaffRequest,
} from "api";
import { useDispatch } from "react-redux";
import {
  recruitmentSelect,
  setStaffRequest,
  setUpdateStaffs,
  setVacancyReasons,
} from "@redux/slices/recruitment";
import { useNavigate } from "react-router-dom";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { useAccess } from "hooks";
import isEqual from "lodash/isEqual";
import { FormStaffRequest } from "./FromStaffRequest";
import { useEffect, useState } from "react";
import { getVacancyReasons } from "api";
import { setRequestUpdated } from "@redux/slices/requests";

import "./styles.css";

export const StaffRequest = ({
  btnBack = true,
  readOnly = false,
  setOpen = null,
  application = "organization",
}) => {
  const { t } = useTranslation(["recruitment", "request", "general"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { staffRequest } = useSelector(recruitmentSelect);
  const status = staffRequest.status;

  const [jobsPositionData, setJobsPositionData] = useState([]);
  const [jobsPosition, setJobsPosition] = useState([]);

  const { HasPermissions } = useAccess();
  const path = application === "organization" ? "/cereza-organization" : "";
  let { canAdd, canChange, canDelete } = HasPermissions(
    `${path}/recruitment/staff-requests`,
    application,
  );
  let { canAdd: canAddVacancyReasons } = HasPermissions(
    `${path}/recruitment/vacancy-reasons`,
    application,
  );

  canAdd = readOnly ? false : canAdd;
  canChange = readOnly ? false : canChange;
  canDelete = readOnly ? false : canDelete;
  canAddVacancyReasons = readOnly ? false : canAddVacancyReasons;

  useEffect(() => {
    const listJobsPosition = async () => {
      try {
        let { data: jobPositions } = await getJobPositions(null);

        setJobsPositionData(jobPositions);

        setJobsPosition(
          _.map(
            _.filter(jobPositions, (job) => {
              return job.status === "APPROVED";
            }),
            (job) => ({
              ...job,
              value: job._id,
              label: job.title,
            }),
          ),
        );
      } catch (e) {
        console.log("error get jobs profile", e);
      }
    };

    listJobsPosition();

    const listVacancyReasons = async () => {
      try {
        const { data } = await getVacancyReasons(null);
        dispatch(setVacancyReasons(data));
      } catch (e) {
        console.log("error get jobs profile", e);
      }
    };

    listVacancyReasons();
  }, []);

  const onSubmit = async (values) => {
    let message = "";

    let { statusOfSigners, ...payload } = { ...values };

    try {
      if (payload._id) {
        const { data } = await putStaffRequest(payload);
        dispatch(setStaffRequest(data));
        message = t("recruitment:staffrequestsuccessfullyupdate");
        dispatch(
          setRequestUpdated({
            type: "staff",
            action: "update",
            data: data,
          }),
        );
      } else {
        const { data } = await postStaffRequest(payload);
        dispatch(setStaffRequest(data));
        dispatch(
          setRequestUpdated({
            type: "staff",
            action: "add",
            data: data,
          }),
        );
        message = t("recruitment:staffrequestsuccessfullycreated");
      }
      dispatch(setUpdateStaffs(true));
      setOpen(false);
      Toast.fire({ icon: "success", title: message });
    } catch (e) {
      Toast.fire({
        icon: "error",
        title: t("recruitment:staffrequesterrorsubmit"),
      });
      console.log("Error to submit form: ", e);
    }
  };

  const deleteItem = async (item) => {
    try {
      await deleteStaffRequest(item);

      dispatch(setUpdateStaffs(true));
      if (setOpen !== null) {
        setOpen(false);
      }

      Toast.fire({
        icon: "success",
        title: t("recruitment:staffrequestsuccessfullydelete"),
      });

      dispatch(
        setStaffRequest({
          vacancyReason: [],
          quantity: 1,
          jobPosition: "",
        }),
      );
      dispatch(
        setRequestUpdated({
          type: "staff",
          action: "delete",
          data: data,
        }),
      );
    } catch (err) {
      console.log(err);
      Toast.fire({
        icon: "error",
        title: t("recruitment:staffrequesterrordelete"),
      });
    }
  };

  return jobsPositionData.length && jobsPosition.length ? (
    <Box>
      <Formik
        initialValues={staffRequest}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form id={"d"} autoComplete="off">
            <FormStaffRequest
              formik={formik}
              canAdd={canAdd}
              canChange={canChange}
              canAddVacancyReasons={canAddVacancyReasons}
              jobsPosition={jobsPosition}
            />
            {!readOnly && (
              <Stack
                mt={2}
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 1, md: 1 }}
              >
                {btnBack && (
                  <UIButton
                    variant="contained"
                    startIcon={<ChevronLeftIcon />}
                    onClick={() =>
                      navigate(
                        "/cereza-organization/recruitment/staff-requests",
                      )
                    }
                    disabled={formik.isSubmitting}
                    sx={{}}
                  />
                )}

                {((canAdd && status === "REVIEW_REQUIRED") ||
                  (canChange &&
                    ["APPROVED", "INACTIVE"].includes(
                      formik.values?.status,
                    ))) && (
                  <UIButton
                    type="submit"
                    label={
                      formik?.values?._id
                        ? t("general:Actualizar")
                        : t("general:Guardar")
                    }
                    loading={formik.isSubmitting}
                    sx={{}}
                  />
                )}
                {((canAdd && status === "REVIEW_REQUIRED") ||
                  (canDelete && status !== "PENDING")) &&
                  formik?.values?._id && (
                    <UIButton
                      label={t("general:Eliminar")}
                      onClick={() => {
                        Swal.fire({
                          title: t("general:Are"),
                          text: t("general:Youwon"),
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: t("general:deleteit"),
                        }).then((result) => {
                          if (result.isConfirmed) {
                            deleteItem(formik.values);
                          }
                        });
                      }}
                      disabled={formik.isSubmitting}
                      sx={{}}
                    />
                  )}
                {!isEqual(formik?.values, formik?.initialValues) && (
                  <>
                    {canAdd && (
                      <UIButton
                        label={t("general:DiscardChanges")}
                        onClick={() => formik.setValues(formik.initialValues)}
                        disabled={formik.isSubmitting}
                        sx={{}}
                      />
                    )}
                  </>
                )}
              </Stack>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  ) : (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <CircularProgress />
    </Stack>
  );
};
